
import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import MySwiper from "@/components/MySwiper.vue";
import { Image } from "vant";
import Swiper, {
  Autoplay,
  EffectCoverflow,
  EffectCube,
  Pagination,
  Navigation,
} from "swiper";
Swiper.use([Autoplay, EffectCoverflow, EffectCube, Pagination, Navigation]);
import "swiper/swiper-bundle.min.css";
// swiper.less/sass/css 决定了基础的样式
import "swiper/swiper.less";
import { monitorEvent } from "@/utils/youMengMonitor";

@Component({
  components: { MySwiper, [Image.name]: Image },
})
export default class toursList extends Mixins(Mixin) {
  @Prop({
    type: Array,
    default: () => {
      return [];
    },
  })
  public projectList!: Array<good.RecommendProductDto>;

  @Watch("projectList", { deep: true })
  changeTourList(val: Array<good.RecommendProductDto>): void {
    this.projectList = val;
  }

  goProDetail(id: string): void {
    window.location.href = location.origin + "/third/#/yuboya/" + id;
    // this.$router.push(`/Yoboya-detail/${id}`);
  }
}
