
import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import {
  Col,
  Row,
  Icon,
  Button,
  Image,
  Swipe,
  SwipeItem,
  Tabs,
  Tab,
  Popup,
  Overlay,
  ImagePreview,
  CountDown,
} from "vant";
import MyNavbar from "@/components/MyNavbar.vue";
import YbyScore from "@/components/YbyScore.vue";
import Comment from "@/views/ProjectDetail/components/Comment.vue";
import recommendYuboya from "@/components/Yuboya/recommendYuboya.vue";
import { calculateDiscount } from "@/utils/index";
import { monitorEvent } from "@/utils/youMengMonitor";

@Component({
  components: {
    Mixin,
    MyNavbar,
    recommendYuboya,
    Comment,
    YbyScore,
    [Icon.name]: Icon,
    [Col.name]: Col,
    [Row.name]: Row,
    [Button.name]: Button,
    [Image.name]: Image,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Popup.name]: Popup,
    [Overlay.name]: Overlay,
    [CountDown.name]: CountDown,
  },
})
export default class Tour extends Mixins(Mixin) {
  public tourInfo: good.TourDto = {};
  public isCollect = false; // 收藏标识
  public showImgs: Array<string> = [];
  public activeTab = "video";
  public showMoreDetail = true;
  public moreDetailBtn = true;

  public showMoreBrandDetail = true;
  public moreBrandDetailBtn = true;

  public tourId = "";
  public tourProductList: Array<ObjectMap> = [];
  public openloading = false;
  public introduceBrand = "";
  public performerImgs: Array<string> = [];
  public showTourList = false;
  public commodityList: Array<ObjectMap> = [];
  public showVideo = {
    show: false,
    item: "",
    index: 1,
  };
  public countShow = true;
  public commentShowList: Array<member.ProductCommentDTO> = [];
  public commentTotal = 0;

  mounted(): void {
    this.tourId = this.$route.params.tourId;
    this.initTourDetails();
    this.getComment();
  }

  getComment(): void {
    this.$api.memberApi.comment.praiseCommentList(
      "02",
      this.tourId,
      { pageNum: 1, pageSize: 3 },
      (data) => {
        this.commentShowList = data.data.records!;
        this.commentTotal = data.data.total!;
      }
    );
  }
  goMallDetails(commodity?: good.CommodityDto): void {
    monitorEvent(
      "TourPage_ClickGood",
      "点击一个商品",
      commodity ? commodity.commodityAbbr : ""
    ); // 埋点：巡演聚合页，点击一个商品
    this.$router.push(
      `/mall/details?commodityId=${commodity ? commodity.commodityInfoId : -1}`
    );
  }
  onChangeVideoPreview(index: number): void {
    this.showVideo.index = index + 1;
  }

  handleImgPreview(imgs: Array<string>, index = 0) {
    ImagePreview({
      images: imgs,
      startPosition: index,
    });
  }
  handleVideoPreview(item: string): void {
    monitorEvent("TourPage_ClickVideo", "点击一个视频"); // 埋点：巡演聚合页，点击一个视频
    this.showVideo.show = true;
    this.showVideo.item = item;
    this.showVideo.index = this.tourInfo.videoUrls!.indexOf(item) + 1;
  }

  // 点击保存图片
  savePic(url: string) {
    let eleLink = document.createElement("a");
    eleLink.download = url;
    eleLink.href = url;
    eleLink.click();
    eleLink.remove();
  }
  //获取 巡演聚合信息
  initTourDetails(): void {
    this.amapLocate();
    this.$api.goodApi.tour.getTourProjectInfo(this.tourId, ({ data }) => {
      if (data) {
        if (data.introduce) {
          data.introduce = data.introduce.replace(
            /<img/g,
            "<img style='max-width:100%;height:auto;'"
          );
        }
        this.tourInfo = data;
        if (data.brandDto) {
          this.introduceBrand = data.brandDto.brandDesc!.replace(
            /<img/g,
            "<img style='max-width:100%;height:auto;'"
          );
        }
        this.performerImgs = data.performers?.map((e) => e.personImg!) || [];
        this.getIsCollect();
        this.initCommodities();
        this.$nextTick(() => {
          const dom = document.querySelector(
            ".detail-rich-text .det"
          )! as HTMLElement;
          const brand_dom = document.querySelector(
            ".brand-detail-rich-text .det"
          )! as HTMLElement;
          this.showMoreDetail =
            dom && dom.offsetHeight > (310 / 750) * window.screen.width;
          this.showMoreBrandDetail =
            brand_dom &&
            brand_dom.offsetHeight > (108 / 750) * window.screen.width;
        });
      }
    });
  }

  // 查询周边商品
  initCommodities(): void {
    this.$api.goodApi.commodity.findCommoditiesPeriphery(
      `${this.tourId}`,
      "COMMODITY_TOUR",
      { page: 1, size: 10 },
      ({ data }) => {
        if (data) {
          this.commodityList = data.records!;
        }
      }
    );
  }
  // 跳转周边商品页面
  goOtGoods(): void {
    monitorEvent("TourPage_ClickGoodList", "点击全部商品"); // 埋点：巡演聚合页，点击全部商品（点击更多）
    this.$router.push(
      `/peripheral-goods/${this.tourId}/3/${this.tourInfo.tourShowTitle}/5`
    );
  }
  // 查询该项目是否已被收藏
  getIsCollect(): void {
    this.$api.memberApi.attentionAndCollection.isCollect(
      this.tourId,
      ({ data }) => {
        this.isCollect = data;
      }
    );
  }
  // 收藏/取消收藏
  collectTour(): void {
    this.$api.memberApi.attentionAndCollection.addOrDelete(
      this.tourId,
      ({ data }) => {
        if (data) {
          if (this.isCollect) {
            this.isCollect = false;
            this.$toast("取消收藏");
          } else {
            this.isCollect = true;
            this.$toast("收藏成功");
          }
        }
      }
    );
  }

  initTourlist(): void {
    this.$api.goodApi.tour.getProjectById(
      this.tourId,
      ({ data }) => {
        this.tourProductList = [];
        this.openloading = false;
        if (!!data && data.length != 0) {
          this.tourProductList = data;
          this.tourProductList.forEach((item) => {
            let val = this.getShowDistance(item.longitude!, item.latitude!);
            Object.assign(item, { showDistance: val });
          });
        }
        this.tourProductList.forEach((item, index) => {
          this.$set(this.tourProductList, index, item);
        });
        this.countShow = false;
        this.$nextTick(() => {
          this.countShow = true;
        });

        this.showTourList = true;
      },
      (error) => {
        error && this.$toast(error);
      }
    );
  }

  // 用于展示的到剧院的距离
  getShowDistance(val1: string, val2: string): string {
    let longitude = this.LocationModule.longitude;
    let latitude = this.LocationModule.latitude;
    if (longitude < 0 || latitude < 0) {
      // 没有成功定位，不展示距离
      return "";
    }

    return calculateDiscount(longitude, latitude, Number(val1), Number(val2));
  }
  openTourList(): void {
    //是否在微博环境
    if (this.getIsWeiBo()) {
      return;
    }
    this.openloading = true;

    this.initTourlist();
  }
}
